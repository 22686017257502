import { atom } from "recoil";

export interface IBranch {
  presence_ID: string;
  Branch_Name: string;
  Address: string;
  Latitude: number;
  Longitude: number;
  Status: string;
  Branch_Opening_Date: string | null;
  Branch_Closing_Date: string | null;
  District_Name: string;
  PINCode: number | null;
  company_name: string | null;
  GoogleMapsLink: string;
  Performance: string;
  District_LGD: number;
  color: string;
  geo_hierarchy: { geo_id: number; geo_name: string }[];
  state: string;
  branch_id: string;
}

export interface ICompany {
  company_id: number;
  branches: IBranch[];
  branch_count: number;
  company_name: string;
  industry: string;
  headquarters: string;
  founded_in: string;
  website: string;
  number_of_location: 3;
  description: string;
  competitors: null | string;
  investors: null | string;
  added_by: string;
  added_at: Date;
  color: string;
  google_map_link: string;
  branch_status: string;
  date_of_opening: string;
  logo: string | null;
  engagement_time: string | null
}

export interface companyProps {
  companies: ICompany[];
  pagination: {
    page: number;
    storiesPerPage: number;
    totalStories: number;
    totalPages: number;
  };
}

const companyState = atom({
  key: "company",
  default: {} as companyProps,
});

export const jsonDataState = atom({
  key: "jsonData",
  default: [] as any,
});

export { companyState };
