import React, { Suspense } from "react";
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { RouteConstants } from "./constants";
import { useRecoilValue } from "recoil";
import { authState } from './states';
import { Spinner } from "./components/ui/spinner/Spinner";

interface ProtectedRouteProps {
    auth: any;
    redirectPath: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ auth, redirectPath }) => {
    if (!auth || !Object.keys(auth).length) {
        return <Navigate to={redirectPath} />;
    }
    return <Outlet />;
}

const Router = () => {
    const auth = useRecoilValue(authState);
    const Login = React.lazy(() => import("./pages/login/Login"));
    const UpdatePassword = React.lazy(() => import("./pages/login/UpdatePassword"));
    const Layout = React.lazy(() => import('./pages/Layout'));
    const Stories = React.lazy(() => import("./pages/Stories"));
    const Microcopy = React.lazy(() => import("./pages/Microcopy"));
    const Tooltips = React.lazy(() => import("./pages/Tooltips"));
    const Data = React.lazy(() => import("./pages/Data"));
    const User = React.lazy(() => import("./pages/User"));
    const Requests = React.lazy(() => import("./pages/Requests"));
    const Companies = React.lazy(() => import("./pages/Companies"));
    const CompanyDetails = React.lazy(() => import('./pages/CompanyDetails'));
    const RolesAndPermissions = React.lazy(() => import("./pages/RolesAndPermissions"));
    const SomethingWentWrong = React.lazy(() => import("./pages/SomethingWentWrong"));
    const ErrorPage = React.lazy(() => import("./pages/ErrorPage"));
    const NotFound = React.lazy(() => import("./pages/NotFound"));

    return (
        <Suspense fallback={
            <Spinner spinnerFlag={true} />
        }>
            <Routes>
                <Route path={RouteConstants.login} element={auth && Object.keys(auth).length ? <Navigate to={RouteConstants.root} /> : <Login />} />
                <Route path={RouteConstants.update_password} element={<UpdatePassword />} />
                <Route element={<ProtectedRoute auth={auth} redirectPath={RouteConstants.login} />}>
                    <Route path={RouteConstants.root} element={<Layout />}>
                        <Route index element={<Stories />} />
                        <Route path={RouteConstants.microcopy} element={<Microcopy />} />
                        <Route path={RouteConstants.tooltips} element={<Tooltips />} />
                        <Route path={RouteConstants.data} element={<Data />} />
                        <Route path={RouteConstants.user} element={<User />} />
                        <Route path={RouteConstants.requests} element={<Requests />} />
                        <Route path={RouteConstants.companies} element={<Companies />} />
                        <Route path={`${RouteConstants.companies}/:companyId`} element={<CompanyDetails />} />
                        <Route path={RouteConstants.roles} element={<RolesAndPermissions />} />
                    </Route>
                    <Route path={RouteConstants.something_went_wrong} element={<SomethingWentWrong />} />
                    <Route path={RouteConstants.error} element={<ErrorPage />} />
                    <Route path={RouteConstants.not_found} element={<NotFound />} />
                </Route>
            </Routes>
        </Suspense >
    );
};

export default Router;
