import { atom } from "recoil";

export interface Stories {
    page: number;
    storiesPerPage: number;
    totalStories: number;
    totalPages: number;
    stories: any[];
}

export const storiesState = atom({
    key: 'storiesData',
    default: {} as any,
});

export const microState = atom({
    key: 'microData',
    default: null as any,
})

export const requestState = atom({
    key: 'requestData',
    default: [] as any,
})

export const toolTipsState = atom ({
    key : 'toolTipsState',
    default: null as any,
})