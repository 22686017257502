import { useState } from "react";
import MyContext from "./MyContext";

const ContextState = (props: any) =>{
    const [openComponent,  setOpenComponent] = useState();
    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [selectedDataEdit, setSelectedDataEdit] = useState();
    const [isbackpress, setIsbackpress] = useState(false);
    const [carouselOriginalData, setCarouselOriginalData] = useState();
    const [paginationData, setPaginationData] = useState<{ type: string, pageno: number, users: number }>({ type: 'live', pageno: 1, users: 25 });
    const [filteredItem, setFilteredItem] = useState<string>('Live');
    const [openReject, setOpenReject] = useState(false);
    const [isReviewClick, setIsReviewClick] = useState(false);
    const [addStepData, setAddStepData] = useState<{
        name: string;
        image: string | null; // Allow image to be either a string (for base64) or null
        title: string;
        description: string;

    }>({
        name: '',
        image: null,
        title: '',
        description: '',
    });

    return (
        <MyContext.Provider value={{ openComponent, setOpenComponent, openEdit, setOpenEdit, selectedDataEdit, setSelectedDataEdit, isbackpress, setIsbackpress, carouselOriginalData, setCarouselOriginalData, paginationData, setPaginationData, filteredItem, setFilteredItem, openReject, setOpenReject, isReviewClick, setIsReviewClick,setAddStepData, addStepData}}>
            {props.children}
        </MyContext.Provider>
    );
}

export default ContextState;