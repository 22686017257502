import { atom } from "recoil";

export interface Dropdown {
    countries: any[],
    states: any[],
    districts: any[],
    sub_districts: any[],
    statuses: any[],
    additionalFields: any[],
    companies: any[],
    companyType: any[],
    userGroup: any[],
}

export const dropdownState = atom({
    key: 'dropdown',
    default: {} as Dropdown,
});

export const datapointsState = atom({
    key: 'dataPagePoints',
    default: {} as any,
});
