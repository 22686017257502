export interface TableHeaderProps {
    ID: string,
    KEYS: { KEY: string, VALUE: string }[]
}

export interface TableProps {
    COMPANY_TABLE: TableHeaderProps,
    PRESENCE_TABLE: TableHeaderProps,
}

export const TABLE_HEADERS: TableProps = {
    COMPANY_TABLE: {
        ID: 'company-info',
        KEYS: [
            { KEY: 'company_name', VALUE: 'Company name' },
            { KEY: 'website', VALUE: 'Website' },
            { KEY: 'industry', VALUE: 'Industry' },
            { KEY: 'branch_count', VALUE: 'Presence' }
        ]
    },
    PRESENCE_TABLE: {
        ID: 'presnece-details',
        KEYS: [
            { KEY: 'presence_ID', VALUE: 'Presence ID' },
            { KEY: 'state', VALUE: 'State' },
            { KEY: 'Address', VALUE: 'Presence address' },
            { KEY: 'Latitude', VALUE: 'Latitude' },
            { KEY: 'Longitude', VALUE: 'Longitude' },
            { KEY: 'PINCode', VALUE: 'Pin code' },
            { KEY: 'GoogleMapsLink', VALUE: 'Google map link' },
            { KEY: 'Branch_Opening_Date', VALUE: 'Date of opening' },
            { KEY: 'Branch_Closing_Date', VALUE: 'Date of closing' },
            { KEY: 'Status', VALUE: 'Status' },
            { KEY: 'Performance', VALUE: 'Performance' },
        ]
    }
}

export const geoRegions = [
    { key: 'country', array: 'countries', value: 'Country' },
    { key: 'state', array: 'states', value: 'State' },
    { key: 'district', array: 'districts', value: 'District' },
    { key: 'sub_district', array: 'sub_districts', value: 'Sub District' },
];

export const COMPANY_FORM_FIELDS = [
    { name: "company_name", label: "Company Name", type: "text", placeholder: "Enter company name", required: true },
    { name: "website", label: "Website", type: "text", placeholder: "Enter website", required: true, isURL: true },
    { name: "headquarters", label: "Headquarter", type: "text", placeholder: "Enter headquarter location", required: true },
    { name: "industry", label: "Industry", type: "text", placeholder: "Enter industry", required: true },
    { name: "founded_in", label: "Open date", type: "datepicker", placeholder: "Enter open date", required: true },
    { name: "description", label: "Description", type: "textarea", placeholder: "Enter description", required: true },
];

export const VALIDATION_CHECK = {
    PINCode: (value: string) => {
        if (!/^\d{6}$/.test(value)) return "Invalid Pincode";
        return null;
    },
    GoogleMapsLink: (value: string) => {
        if (value && !/^(https?:\/\/)?([a-z0-9.-]+)\.[a-z]{2,}(\/[\w.-]*)*\/?(\?[=&\w.-]*)?$/i.test(value)) return "Invalid URL";
        return null;
    },
    Latitude: (value: string) => {
        const parsedValue = parseFloat(value);
        if (parsedValue < -90 || parsedValue > 90) return "Latitude must be between -90 and 90";
        return null;
    },
    Longitude: (value: string) => {
        const parsedValue = parseFloat(value);
        if (parsedValue < -180 || parsedValue > 180) return "Longitude must be between -180 and 180";
        return null;
    },
};

export const PRESENCE_PAYLOAD_FIELDS = ['presence_ID', 'Branch_Name', 'Address', 'Latitude', 'Longitude', 'latlong', 'Status', 'Branch_Opening_Date', 'Branch_Closing_Date', 'District_Name', 'PINCode', 'State', 'SubDistrict', 'Village', 'GoogleMapsLink', 'Performance'];

export const PERFORMANCE_SELECT = [
    {name: 'N/A', key: ''},
    {name: 'Good', key: 'good'},
    {name: 'Bad', key: 'bad'},
]