import { BrowserRouter } from 'react-router-dom';

// CSS
import './styles/main.css';

// Components
import { Spinner } from './components/ui/spinner/Spinner';
import Toast from './components/ui/toast/Toast';
import Router from './Router';
import ContextState from './context/ContextState';

function App() {
	return (
		<div className="App">
			<ContextState>
				<Spinner />
				<Toast />
				<BrowserRouter>
					<Router />
				</BrowserRouter>
			</ContextState>
		</div>
	);
}

export default App;
