import { atom } from 'recoil';

export interface geoJSONProps {
    type: string;
    features: any;
    properties: {
        Name: string;
        id: string;
        region: string;
    }
}

const geoJsonState = atom({
    key: 'geojson',
    default: {} as geoJSONProps
});

export { geoJsonState };